import React from 'react';
import chair from './chair.png';
import leftAngle from './leftangle.png';
import rightAngle from './rightangle.png';
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import {ChannelPlayer} from './Channel-Player';
import {TwitchClip} from 'react-twitch-embed';
import {Grid, GridColumn, GridRow, Image} from "semantic-ui-react";


function App() {
    return (
        <div className="App">
            <Grid centered columns={16}>
                <GridRow className="App-row">
                    <GridColumn className="App-header-grid" computer={12} mobile={16} tablet={14}>
                        <div className="App-header">
                            <div className="Header-leftBuffer">
                                <Image src={leftAngle} size="small" />
                            </div>
                            CHAIR
                            <div className="App-Logo-container">
                                <Image className="App-logo" src={chair} size="small" circular/>
                            </div>
                            FANS
                            <div className="Header-rightBuffer">
                                <Image src={rightAngle} size="small"/>
                            </div>
                        </div>
                    </GridColumn>
                </GridRow>
                <GridRow className="App-row">
                    <GridColumn className="App-tagline-container" computer={12} mobile={16} tablet={14}  >
                        <div className="App-tagline">
                            All the Hottest Seat Content!
                        </div>
                    </GridColumn>
                </GridRow>
                <GridRow className="App-row">
                    <GridColumn id="App-content" className="App-content" computer={12} mobile={16} tablet={14}  >
                        <ChannelPlayer channel="shroud" allowFullScreen={false} autoplay={false} height={400} id="shroud" muted={true} width={800}/>
                    </GridColumn>
                </GridRow>
                <GridRow className="App-row">
                    <GridColumn id="Naughty-Clip" className="App-split-content App-split-left" computer={6} tablet={8} mobile={8}>
                        <TwitchClip clip="TenderSpikyTireCopyThis" id="Naughty-Clip" autoplay={false} width="80%" height="500"/>
                    </GridColumn>
                    <GridColumn className="App-split-content App-split-right" computer={6} tablet={6} mobile={8}>
                        <p>Hot Behind the Seat action!</p>
                    </GridColumn>
                </GridRow>
                <GridRow className="App-row">
                    <GridColumn className="App-split-content App-split-left" computer={6} tablet={6} mobile={8}>
                        <p>Teaching Exit a Lesson on who is Superior on the Stream!</p>
                    </GridColumn>
                    <GridColumn id="Exit-Thrown" className="App-split-content App-split-right" computer={6} tablet={8} mobile={8}>
                        <TwitchClip clip="HandsomeCrunchyBunnyWow" id="Exit-Thrown" autoplay={false} width="80%" height="500"/>
                    </GridColumn>
                </GridRow>
                <GridRow className="App-row">
                    <GridColumn id="Chair-Dance" className="App-split-content App-split-left" computer={6} tablet={8} mobile={8}>
                        <TwitchClip clip="SpookyDeliciousSangChefFrank" id="Chair-Dance" autoplay={false} width="80%" height="500"/>
                    </GridColumn>
                    <GridColumn className="App-split-content App-split-right" computer={6} tablet={6} mobile={8}>
                        <p>This Hot Seat is Fire on the Dance Floor! Watch me put in the Work!</p>
                    </GridColumn>
                </GridRow>
                <GridRow className="App-row">
                    <GridColumn className="App-footer" computer={12} mobile={16} tablet={14}  >
                        <div className="App-footer-content">
                            <p>
                                check out my home channel at <a href="https://twitch.tv/exitlefttv">exitlefttv</a> for more videos of my hot seat action!
                            </p>
                            <p>
                                You can also acquire one of my brothers at <a href="https://gtracing.com/collections/gtracing-footrest-series/products/footrest-series-gt004-red">GT Racing</a> when available.
                            </p>
                        </div>
                    </GridColumn>
                </GridRow>
            </Grid>
        </div>
    );
}

export default App;
