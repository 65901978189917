import React from 'react';
import {TwitchPlayer} from 'react-twitch-embed';

interface PlayerProps {
    channel: string;
    id: string;
    muted: boolean;
    autoplay: boolean;
    allowFullScreen: boolean;
    width: number;
    height: number;
}

interface PlayerState {
    playerWidth: number;
    playerHeight: number;
}


export class ChannelPlayer extends React.Component<PlayerProps, PlayerState> {
    constructor(props: PlayerProps) {
        super(props);
        this.state = {
            playerHeight: 0,
            playerWidth: 0
        }
    }

    render() {
        return (
            <TwitchPlayer
                channel="exitlefttv"
                height={this.state.playerHeight}
                width={this.state.playerWidth}
                id="App-content"
                theme="dark"
                muted
                autoplay="false"
                onVideoPause={() => console.log(':(')} />);
    }
}